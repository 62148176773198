@import 'styles/fonts.css';
@import url('https://fonts.googleapis.com/css?family=Material+Icons');
@import-normalize;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    line-height: 1.4em;
    font: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;

    &:hover,
    &:active,
    &:focus {
        outline: none;
    }
}

html {
    font-size: 62.5%;
    -webkit-print-color-adjust: exact;
}

a {
    text-decoration: none;
    color: inherit;
}

input,
button,
select {
    appearance: none;
    border: 0;
    border-radius: 0;
    background: none;
    padding: 0;
}

button {
    cursor: pointer;
}

h1 {
    margin: 0;
}
