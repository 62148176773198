@import 'styles/variables.css';

.view-content {
    padding-bottom: calc(var(--height-lg) * 2);
}

.label {
    color: var(--gray-dark-1);
    font-weight: var(--bold);
    font-size: var(--font-size-sm);
}

.top-level-config {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    font-size: var(--font-size-sm);

    & > .editor {
        width: 50%;
        padding: var(--gap-xs) var(--gap-sm);
        font-size: var(--font-size-sm);

        & .content {
            width: 75%;
            display: flex;
            align-items: center;
        }

        & .label {
            min-width: 160px;
            padding-right: var(--gap-md);
        }
    }
}

.workflow-actions {
    margin: var(--gap-md) 0;

    & .label {
        margin: 0 0 var(--gap-sm);
    }
}

.buttons{
    display: flex;
    padding: var(--gap-md) var(--gap-lg);
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--brand-blue);
    width: 100%;
    & > div > * {
        margin-left: var(--gap-md);
    }
}

.output-handlers {
    display: flex;
    flex-wrap: wrap;
    & > * {
        margin-right: var(--gap-sm);
        margin-bottom: var(--gap-sm);
    }
}

.action-id-editor {
    display: flex;
    align-items: center;
    box-sizing: border-box;

    & .separator {
        padding: 0 var(--gap-xs);
        font-weight: var(--bold);
    }

    & .selector {
        width: 48%;
    }

    & .identifier {
        width: 48%;
        display: flex;
    }
}

.buttons-container {
    & > * {
        margin: 0 var(--gap-sm);
    }
}

.button {
    display: flex;
    align-items: center;
}
